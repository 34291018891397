// 病害调查记录
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'YCYSMC',
                    label: '遗产要素'
                },
                {
                    prop: 'YCYSDTHJBTPMC',
                    label: '遗产要素单体'
                },
                // {
                //     prop: 'XTSFFSBH',
                //     label: '形态是否发生变化',
                //     width: 80,
                //     transList: {
                //         0: "是",
                //         1: "否"
                //     }
                // }, 
                {
                    prop: 'CJSJ',
                    label: '创建时间',
                    substr: true
                },
                {
                    prop: 'PICFIR',
                    label: '照片',
                    showImg: true
                },
                SHZT,
                DJZT
            ],
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'toPicCompare',
                name: '对比图',
                round: true,
                backColor: '#008c8c',
            },
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'conditionSearchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
    includes: ['PG', 'QKSM', 'YCYSBCZT']
};
export default selfData;